import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, of, tap } from 'rxjs';
import { Role } from '../../../constants/role.enum';
import { environment } from '../../../environments/environment';
import { LoginResponse } from '../../../shared/interfaces/loginResponse.interface';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private apiUrl = `${environment.apiUrlLogin}`;

  private isAuthenticatedSubject = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {
    this.initializeAuthStatus();
  }

  initializeAuthStatus(): Observable<boolean> {
    if (this.isBrowser()) {
      const token = sessionStorage.getItem('auth-token');
      this.isAuthenticatedSubject.next(!!token);
    }
    return this.isAuthenticatedSubject.asObservable();
  }

  login(
    identifier: string,
    password: string
  ): Observable<{ success: boolean; isAdmin: boolean }> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http
      .post<LoginResponse>(this.apiUrl, { identifier, password }, { headers })
      .pipe(
        tap(response => {
          if (this.isBrowser()) {
            sessionStorage.setItem('auth-token', response.token);
            sessionStorage.setItem('username', response?.user?.nome);
            sessionStorage.setItem('documento', response?.user?.documento);
            sessionStorage.setItem('email', response?.user?.email);
            sessionStorage.setItem('id_user', response?.user?.id.toString());
            sessionStorage.setItem(
              'preferencia_idioma',
              response?.user?.preferencia_idioma
            );
            sessionStorage.setItem('telefone', response?.user?.telefone);
            sessionStorage.setItem('iso2', response?.user?.iso2);
            sessionStorage.setItem(
              'distribuidora',
              response?.distribuidora || 'Sem Distribuidora'
            );
            sessionStorage.setItem(
              'id_grp_distribuidora',
              response?.user?.id_grp_distribuidora
                ? response.user.id_grp_distribuidora.toString()
                : ''
            );
            sessionStorage.setItem(
              'admSystem',
              response.user.isAdmin.toString()
            );
            sessionStorage.setItem(
              'modulos_acesso',
              JSON.stringify(response.modulos_acesso)
            );
          }
          this.isAuthenticatedSubject.next(true);
        }),
        map(response => ({
          success: !!response.token,
          isAdmin: false,
          // isAdmin: response?.user?.isAdmin || false
        })),
        catchError(error => {
          console.error('Erro durante o login:', error);
          return of({ success: false, isAdmin: false });
        })
      );
  }

  logout(): void {
    this.isAuthenticatedSubject.next(false);
    if (this.isBrowser()) {
      sessionStorage.clear();
      localStorage.clear();
    }
  }

  isLoggedIn(): Observable<boolean> {
    return this.isAuthenticatedSubject.asObservable();
  }

  getUserRole(): Observable<string> {
    const isAdmin = JSON.parse(sessionStorage.getItem('admSystem') || 'false');
    return of(isAdmin ? Role.ADMIN : Role.USER);
  }

  private isBrowser(): boolean {
    return (
      typeof window !== 'undefined' &&
      typeof window.sessionStorage !== 'undefined'
    );
  }
}
