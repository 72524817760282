<app-header
  [title]="'PERFIL' | translate"
  [buttonBack]="false"
  [alignTitle]="'justify-between'"></app-header>

<app-loading
  [isLoading]="isLoading"
  [loadingMessage]="'Atualizando'"></app-loading>

<div
  class="grid grid-cols-[min-content,3fr,1.5fr,min-content] gap-10 mb-8 mr-6 ml-6"
  [class.opacity-50]="isLoading">
  <div></div>

  <div>
    <div class="text-[#2F5E73] text-[1.6rem] mb-5 text-center">
      <span class="font-medium uppercase">{{
        'DADOS_PESSOAIS' | translate
      }}</span>
    </div>

    <div class="grid grid-cols-2 gap-x-6 gap-y-1 2xl:col-span-6">
      <app-generic-input
        id="user_name"
        label="{{ 'NAME' | translate }}"
        controlName="user_name"
        [placeholder]="'NOME' | translate"
        [form]="form">
      </app-generic-input>

      <app-generic-input
        id="email"
        label="{{ 'EMAIL' | translate }}"
        type="email"
        controlName="email"
        [placeholder]="'EMAIL' | translate"
        [form]="form">
      </app-generic-input>

      <div class="flex flex-row gap-5 w-full h-full">
        <app-input-select
          class="w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/3 h-12 outline-none text-base font-normal text-tertiary ng-untouched ng-pristine ng-valid"
          (change)="setMask()"
          id="iso2"
          label="{{ 'DDI' | translate }}"
          controlName="iso2"
          [form]="form"
          [options]="optionsDDI">
          <ng-template let-option>
            <img
              [src]="option.flag"
              alt="{{ option.label }}"
              class="flag-icon" />
            {{ option.label }}
          </ng-template>
        </app-input-select>
        <app-generic-input
          id="phone"
          class="flex-1 h-12 outline-none text-base font-normal text-tertiary ng-pristine ng-valid ng-touched"
          label="{{ 'PHONE' | translate }}"
          controlName="phone_number"
          [mask]="phoneMask"
          [clearIfNotMatch]="true"
          [form]="form"
          type="text">
        </app-generic-input>
      </div>

      <app-input-select
        id="language"
        label="{{ 'LANGUAGE' | translate }}"
        controlName="language"
        [form]="form"
        [options]="selectOptions">
      </app-input-select>
    </div>
  </div>

  <div>
    <div class="text-[#2F5E73] text-[1.6rem] mb-5 text-center">
      <span class="font-medium uppercase">{{
        'ALTERAR_SENHA' | translate
      }}</span>
    </div>

    <div class="grid grid-cols-1 gap-1">
      <app-generic-input
        id="current_password"
        label="{{ 'SENHA_ATUAL' | translate }}"
        type="password"
        controlName="password"
        [placeholder]="'SENHA_ATUAL' | translate"
        [form]="form">
      </app-generic-input>

      <app-generic-input
        id="new_password"
        label="{{ 'NOVA_SENHA' | translate }}"
        type="password"
        controlName="new_password"
        [placeholder]="'NOVA_SENHA' | translate"
        [form]="form">
      </app-generic-input>

      <app-generic-input
        id="password_confirmation"
        label="{{ 'CONFIRMAR_SENHA' | translate }}"
        type="password"
        controlName="password_confirmation"
        [placeholder]="'CONFIRMAR_SENHA' | translate"
        [form]="form">
      </app-generic-input>
    </div>
  </div>

  <div class="flex items-start justify-end w-20">
    <button
      type="submit"
      (click)="savePersonalData()"
      [disabled]="form.invalid"
      class="text-white font-bold rounded focus:outline-none focus:shadow-outline"
      [ngClass]="{
        'opacity-50 cursor-not-allowed': form.invalid
      }">
      <img
        [ngClass]="{
          'enabled-icon': form.valid,
          'disabled-icon': form.invalid
        }"
        src="assets/icons/save_button.svg"
        alt="Salvar"
        width="45"
        height="45" />
    </button>
  </div>
</div>
