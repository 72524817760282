import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.css',
})
export class HeaderComponent implements OnInit {
  @Input() title: string = '';
  @Input() subtitle: boolean = false;
  @Input() buttonBack: boolean = false;
  @Input() alignTitle: string = '';
  @Input() screen: string = '';
  @Input() paddingRow: boolean = false;
  @Output() back = new EventEmitter<void>();

  distribuidora: string | null = sessionStorage.getItem('distribuidora');
  name: string | null = sessionStorage.getItem('username');

  constructor() {}

  ngOnInit(): void {
    if (
      !this.distribuidora ||
      this.distribuidora === 'null' ||
      this.distribuidora.trim() === ''
    ) {
      this.distribuidora = 'Sem Distribuidora';
    }
  }

  actionBack(): void {
    this.back.emit();
  }
}
