import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { HeaderComponent } from '../../../../shared/components/header/header.component';
import { CommonModule } from '@angular/common';
import { GenericInputComponent } from '../../../../shared/components/generic-input/generic-input.component';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { InputSelectComponent } from '../../../../shared/components/input-select/input-select.component';
import { debounceTime, forkJoin, Subject, takeUntil } from 'rxjs';
import { ProfileService } from '../../../../shared/services/profile.service';
import { PersonalDataPayload } from '../../../../shared/interfaces/profile/personalDataPayload.interface';
import { ChangePassword } from '../../../../shared/interfaces/profile/changePasswordPayload.interface';
import { ToastrService } from 'ngx-toastr';
import { LoadingComponent } from '../../../../shared/components/loading/loading.component';

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [
    HeaderComponent,
    CommonModule,
    GenericInputComponent,
    ReactiveFormsModule,
    InputSelectComponent,
    TranslateModule,
    LoadingComponent,
  ],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.css',
})
export class ProfileComponent implements OnInit, OnDestroy {
  private translate = inject(TranslateService);
  private profileService = inject(ProfileService);
  private fb = inject(FormBuilder);
  private toastr = inject(ToastrService);
  form: FormGroup;
  isLoading: boolean = false;
  loadingMessage: string = 'Atualizando';

  phoneMask: string = '';
  previousIso2: string | null = '';
  optionsDDI = [
    { value: 'br', label: 'BR', img: 'assets/icons/bra.png' },
    { value: 'us', label: 'USA', img: 'assets/icons/usa.png' },
    { value: 'es', label: 'ES', img: 'assets/icons/esp.png' },
  ];
  selectOptions = [
    { value: 'pt-br', label: 'pt-br' },
    { value: 'en', label: 'en' },
    { value: 'es', label: 'es' },
  ];

  username: string | null = '';
  email: string | null = '';
  preferencia_idioma: string | null = '';
  iso2: string | null = '';
  phone_number: string | null = '';
  id_user: string | null = '';

  private updating = false;

  unsubscribe$: Subject<void> = new Subject<void>();

  constructor() {
    this.form = this.fb.group({
      id_user: [null],
      user_name: [null],
      phone_number: [null],
      iso2: [null],
      language: [null],
      password: [null],
      password_confirmation: [null, confirmPasswordValidator],
      new_password: [null, confirmPasswordValidator],
      email: [
        null,
        [
          Validators.email,
          Validators.pattern(
            '^([a-zA-Z0-9_-]+([.][a-zA-Z0-9_-]+)*[@][a-zA-Z0-9_-]+([.][a-zA-Z0-9_-]+)*[.][a-zA-Z]{2,4}){0,1}$'
          ),
        ],
      ],
    });

    this.iso2 = sessionStorage?.getItem('iso2');
    this.phone_number = sessionStorage?.getItem('telefone');
    this.preferencia_idioma = sessionStorage?.getItem('preferencia_idioma');
    this.email = sessionStorage?.getItem('email');
    this.username = sessionStorage?.getItem('username');
    this.id_user = sessionStorage?.getItem('id_user');
    this.previousIso2 = this.iso2;
  }

  ngOnInit(): void {
    this.form
      .get('new_password')
      ?.valueChanges.pipe(debounceTime(100), takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.updateForm('password_confirmation');
      });

    this.form
      .get('password_confirmation')
      ?.valueChanges.pipe(debounceTime(100), takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.updateForm('new_password');
      });

    this.form.get('email')?.setValue(this.email);
    this.form.get('iso2')?.setValue(this.iso2);
    this.setMask();
    this.form.get('phone_number')?.setValue(this.phone_number);
    this.form.get('language')?.setValue(this.preferencia_idioma);
    this.form.get('user_name')?.setValue(this.username);
    this.form.get('id_user')?.setValue(this.id_user);
  }

  setMask(): void {
    const valueSelected = this.form.get('iso2')?.value;
    const phoneNumberControl = this.form.get('phone_number');
    const verifyPreviousIso2 = valueSelected === this.previousIso2;
    this.phoneMask = '';

    switch (valueSelected) {
      case 'br':
        this.phoneMask = '+00 (00) 00000-0000';
        break;
      case 'us':
        this.phoneMask = '+0 (000) 000-0000';
        break;
      case 'es':
        this.phoneMask = '+00 (00) 00000-0000';
        break;
      default:
        this.phoneMask = '';
        break;
    }

    if (!verifyPreviousIso2) {
      phoneNumberControl?.setValue('');
    }

    this.previousIso2 = valueSelected;
  }

  private updateForm(
    fieldName: 'new_password' | 'password_confirmation'
  ): void {
    if (!this.updating) {
      this.updating = true;
      const otherField = this.form.get(
        fieldName === 'new_password' ? 'password_confirmation' : 'new_password'
      );
      if (otherField) {
        otherField.markAsTouched();
        otherField.updateValueAndValidity();
      }
      this.updating = false;
    }
  }

  private logInvalidFields(): void {
    Object.keys(this.form.controls).forEach(controlName => {
      const control = this.form.get(controlName);
      if (control && control.invalid) {
        console.log(`Campo inválido: ${controlName}`, control.errors);
      }
    });
  }

  formatPhone(phone: string): string {
    const iso2 = this.form.get('iso2')?.value;

    if (!phone) {
      return '';
    }
    let phoneNumber = phone.replace(/\D/g, '');
    switch (iso2) {
      case 'br':
        phoneNumber = `${phoneNumber.substring(0, 2)} (${phoneNumber.substring(2, 4)}) ${phoneNumber.substring(4, 9)}-${phoneNumber.substring(9, 13)}`;
        break;
      case 'us':
        phoneNumber = `${phoneNumber.substring(0, 1)} (${phoneNumber.substring(1, 4)}) ${phoneNumber.substring(4, 7)}-${phoneNumber.substring(7, 11)}`;
        break;
      case 'es':
        phoneNumber = `${phoneNumber.substring(0, 2)} (${phoneNumber.substring(2, 4)}) ${phoneNumber.substring(4, 9)}-${phoneNumber.substring(9, 13)}`;
        break;
    }
    return phoneNumber;
  }

  private updateSessionData(objForm: PersonalDataPayload): void {
    sessionStorage?.setItem('telefone', objForm.telefone ?? '');
    sessionStorage?.setItem(
      'preferencia_idioma',
      objForm.preferencia_idioma ?? ''
    );
    sessionStorage?.setItem('iso2', objForm.iso2 ?? '');
    sessionStorage?.setItem('email', objForm.email ?? '');
    sessionStorage?.setItem('username', objForm.nome ?? '');
  }

  async savePersonalData(): Promise<void> {
    this.isLoading = true;
    this.loadingMessage = this.translate.instant('ATUALIZADO');
    const objForm = this.form.getRawValue();
    const id = Number(objForm.id_user);
    objForm.telefone = this.formatPhone(objForm.phone_number);
    const payload: PersonalDataPayload = {
      nome: objForm.user_name,
      email: objForm.email,
      preferencia_idioma: objForm.language,
      iso2: objForm.iso2,
      telefone: objForm.telefone,
    };

    const requests = [];

    const personalDataRequest = this.profileService
      .changePersonalData(id, payload)
      .pipe(takeUntil(this.unsubscribe$));
    requests.push(personalDataRequest);

    if (
      objForm.password &&
      objForm.password_confirmation &&
      objForm.new_password
    ) {
      const passwordPayload: ChangePassword = {
        current_password: objForm.password,
        new_password: objForm.new_password,
        confirm_new_password: objForm.password_confirmation,
      };

      const passwordRequest = this.profileService
        .changePassword(id, passwordPayload)
        .pipe(takeUntil(this.unsubscribe$));

      requests.push(passwordRequest);
    }

    forkJoin(requests).subscribe({
      next: () => {
        this.updateSessionData(payload);
        this.isLoading = false;
        this.toastr.success(this.translate.instant('SAVED'));
      },
      error: () => {
        this.isLoading = false;
      },
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

export const confirmPasswordValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  if (!control.parent || !control) {
    return null;
  }

  const password = control.parent.get('new_password');
  const passwordConfirm = control.parent.get('password_confirmation');

  if (!password?.value || !passwordConfirm?.value) {
    return null;
  }

  if (passwordConfirm.value === '') {
    return null;
  }

  if (password.value === passwordConfirm.value) {
    return null;
  }

  if (control === passwordConfirm) {
    return { passwordsNotMatching: true };
  }

  return null;
};
